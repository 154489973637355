var render = function() {
  var _vm$chargingStation, _vm$meterReadings

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report",
    {
      attrs: {
        renderBackAndForthFooter: false,
        isProgressDone: true,
        showFooter: false
      }
    },
    [
      _c("v-card-title", {
        staticClass: "text-h4 px-0 text-truncate"
      }),
      !_vm.isLoading
        ? _c(
            "v-container",
            {
              staticClass: "padded"
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: ""
                          }
                        },
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass: "pl-0"
                            },
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: _vm.goToChargingStationOverview
                                  }
                                },
                                [
                                  _c("v-icon", [_vm._v("mdi-menu-left")]),
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.thg.ThgChargingStationDetail.back"
                                      )
                                    ) + " "
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-card-title",
                            {
                              staticClass: "text-h4 px-0 text-truncate"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm$chargingStation =
                                      _vm.chargingStation) === null ||
                                      _vm$chargingStation === void 0
                                      ? void 0
                                      : _vm$chargingStation.name
                                  ) +
                                  " "
                              ),
                              _c("v-spacer"),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "px-4",
                                      attrs: {
                                        small: "",
                                        elevation: "0",
                                        color: "primary"
                                      },
                                      on: {
                                        click: function click($event) {
                                          _vm.isUpdateDialogActive = true
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("change")) + " "
                                      )
                                    ]
                                  ),
                                  !(
                                    (_vm$meterReadings = _vm.meterReadings) !==
                                      null &&
                                    _vm$meterReadings !== void 0 &&
                                    _vm$meterReadings.length
                                  )
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "px-4",
                                          attrs: {
                                            color: "error",
                                            small: "",
                                            outlined: ""
                                          },
                                          on: {
                                            click: function click($event) {
                                              _vm.isDeleteDialogActive = true
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("delete")) + " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-subtitle",
                            {
                              staticClass: "pl-0"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.thg.ThgChargingStationDetail.created",
                                      {
                                        date: _vm.chargingStationTimeStamp
                                      }
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12"
                      }
                    },
                    [
                      _c("thg-charging-station-card", {
                        attrs: {
                          selection: _vm.chargingStation,
                          meterReadings: _vm.meterReadings,
                          loading: _vm.isLoading
                        }
                      }),
                      _c("Debug", {
                        attrs: {
                          debug: _vm.chargingStation
                        }
                      }),
                      _c("Debug", {
                        attrs: {
                          debug: _vm.meterReadings
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-container",
            {
              staticClass: "padded"
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        flat: "",
                        "max-width": "500"
                      }
                    },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "a",
                            {
                              on: {
                                click: _vm.goToChargingStationOverview
                              }
                            },
                            [
                              _c("v-icon", [_vm._v("mdi-menu-left")]),
                              _vm._v("zurück zur Übersicht")
                            ],
                            1
                          ),
                          _c("v-skeleton-loader", {
                            staticClass: "my-4",
                            attrs: {
                              type: "heading"
                            }
                          }),
                          _c("v-skeleton-loader", {
                            attrs: {
                              type: "text"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        offset: "0",
                        cols: "12"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mb-10"
                        },
                        [
                          _c("v-skeleton-loader", {
                            attrs: {
                              type: "table-heading, table-tbody, table-tfoot"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
      _c("confirm-action-dialog", {
        attrs: {
          isDialogActive: _vm.isDeleteDialogActive,
          title: _vm.$t(
            "components.thg.ThgChargingStationDetail.confirmDeleteTitle"
          ),
          subtitle: _vm.$t(
            "components.thg.ThgChargingStationDetail.confirmDeleteSubtitle"
          ),
          leftLoading: _vm.isDeleteDialogLoading,
          rightLoading: _vm.isDeleteDialogLoading
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          leftClick: function leftClick($event) {
            _vm.isDeleteDialogActive = false
          },
          rightClick: _vm.deleteChargingStation
        }
      }),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.isUpdateDialogActive,
            title: _vm.$t(
              "components.thg.ThgChargingStationDetail.confirmUpdateTitle"
            ),
            subtitle: _vm.$t(
              "components.thg.ThgChargingStationDetail.confirmUpdateSubtitle"
            ),
            leftLoading: _vm.isUpdateDialogLoading,
            rightLoading: _vm.isUpdateDialogLoading,
            hideLeft: true,
            hideRight: true
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isUpdateDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isUpdateDialogActive = $event
            }
          }
        },
        [
          _c("thg-charging-station-form", {
            attrs: {
              isEdit: true,
              value: _vm.chargingStation
            },
            on: {
              save: _vm.updateChargingStation
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }