var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: _vm.width,
        height: _vm.height,
        "data-name": "Layer 1",
        viewBox: _vm.viewBox
      }
    },
    [
      _c("title", [_vm._v("transfer money")]),
      _c("circle", {
        attrs: {
          cx: "471",
          cy: "373",
          r: "373",
          fill: "#f2f2f2"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "148",
          cy: "774",
          fill: "#f2f2f2",
          rx: "148",
          ry: "23.68"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "870",
          cy: "736.969",
          fill: "#f2f2f2",
          rx: "229",
          ry: "36.64"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#2f2e41",
          d:
            "M869.62034,435.94453s1.94963,6.49877-9.09828,12.34766-14.2973,27.94472-1.29975,34.44349,16.8968,14.94718,10.398,20.79607-18.19656,21.446-2.59951,25.34521,17.54668,11.69779,12.99754,14.94717,10.398-5.84889,10.398-5.84889,2.59951,15.597,16.24693,11.69779,51.99017-77.98526,32.49385-88.38329S869.62034,435.94453,869.62034,435.94453Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#ffb9b9",
          d:
            "M893.17509,498.69674s6.74118,23.59415-5.05589,25.27945a46.05232,46.05232,0,0,0-20.22356,8.42648l21.90885,28.65h40.44712l25.27946-25.27945s-33.70594-25.27945-26.96475-40.44712S893.17509,498.69674,893.17509,498.69674Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M893.17509,498.69674s6.74118,23.59415-5.05589,25.27945a46.05232,46.05232,0,0,0-20.22356,8.42648l21.90885,28.65h40.44712l25.27946-25.27945s-33.70594-25.27945-26.96475-40.44712S893.17509,498.69674,893.17509,498.69674Z",
          opacity: ".1",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("polygon", {
        attrs: {
          fill: "#ffb9b9",
          points:
            "852.787 740.779 891.549 754.261 918.513 755.946 916.828 732.352 861.213 715.904 852.787 740.779"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#575a89",
          d:
            "M953.00312,786.88248s0,23.59415,3.37059,23.59415,48.87361-5.05589,62.356,0,33.70594-13.48237,23.59416-23.59415-26.60567-25.27945-36.897-21.90886-52.42374,11.79708-52.42374,11.79708Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#575a89",
          d:
            "M829.13381,764.97362s-20.22356,40.44712-6.74119,43.81772,28.65005-1.6853,30.33534-3.3706,25.27946-8.42648,28.65-5.05589,3.37059-13.48237,3.37059-13.48237Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#2f2e41",
          d:
            "M845.98678,709.35883s-32.02064,43.81771-8.42649,55.61479,136.509,11.79708,144.93552,0,11.79708-48.8736,0-52.2442S845.98678,709.35883,845.98678,709.35883Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M845.98678,709.35883s-32.02064,43.81771-8.42649,55.61479,136.509,11.79708,144.93552,0,11.79708-48.8736,0-52.2442S845.98678,709.35883,845.98678,709.35883Z",
          opacity: ".2",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "856.157",
          cy: "420.572",
          r: "38.762",
          fill: "#ffb9b9"
        }
      }),
      _c("path", {
        attrs: {
          fill: _vm.color,
          d:
            "M845.98678,532.40267l33.70593-6.74119s8.42649,26.96475,35.39123,21.90886,33.70594-21.90886,33.70594-21.90886,20.22356,8.42649,21.90886,8.42649,23.59415,8.42648,23.59415,13.48237-25.27945,55.61479-25.27945,55.61479l3.37059,91.006-124.712-3.3706,8.42649-89.32072Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#ffb9b9",
          d:
            "M827.44851,576.22039s-10.11178,89.32072-3.37059,96.06191,33.70593,25.27945,33.70593,25.27945V677.33819l-13.48237-25.27945,13.48237-58.98539Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#ffb9b9",
          d:
            "M1001.87673,573.69244s-5.89854,91.84867-5.89854,95.21927-15.16767,15.16767-20.22356,15.16767-8.42649-8.42649-8.42649-8.42649l11.79708-13.48237-8.42648-69.09717Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#2f2e41",
          d:
            "M985.86641,702.61764s75.83835-42.13242,77.52365,0-38.76183,58.98539-38.76183,58.98539-104.4884,31.332-108.70164,19.87922-12.63972-29.991-5.89854-31.6763,15.16767-5.05589,18.53827-1.6853,45.503-25.27945,45.503-25.27945Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "none",
          stroke: "#000",
          "stroke-miterlimit": "10",
          "stroke-width": "2",
          d:
            "M1021.25764,697.56175s-15.16767,6.74119-20.22356,11.79708-26.96475,13.48237-26.96475,13.48237",
          opacity: ".2",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: _vm.color,
          d:
            "M856.09856,535.77326l-10.11178-3.37059s-37.07653,38.76183-25.27945,48.87361,18.53826,13.48237,21.90885,16.853,20.22356,5.05589,20.22356,5.05589Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: _vm.color,
          d:
            "M979.12522,544.19975l15.16767,3.37059s11.79708,25.27945,15.16767,28.65-5.05589,1.68529-10.11178,8.42648-10.11178,18.53826-20.22356,18.53826-23.59415-16.853-23.59415-16.853Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#2f2e41",
          d:
            "M849.35737,717.78531s-64.04128-45.503-85.95013-11.79707,94.37661,79.20894,111.22958,85.95013,32.02064,13.48237,32.02064,13.48237,15.16767-37.07653,15.16767-43.81771-11.79708-6.74119-15.16767-6.74119-18.53826-3.37059-21.90886-8.42648S849.35737,717.78531,849.35737,717.78531Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "none",
          stroke: "#000",
          "stroke-miterlimit": "10",
          "stroke-width": "2",
          d:
            "M813.96614,705.98824s16.853,13.48237,32.02064,16.853,20.22356,11.79708,20.22356,11.79708",
          opacity: ".2",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#575a89",
          d:
            "M837.732,642.62175l1.34634,71.35617a8.93275,8.93275,0,0,0,9.03153,8.76368l132.78977-1.492a8.93275,8.93275,0,0,0,8.81757-8.4177l4.03063-69.86415a8.93277,8.93277,0,0,0-8.91793-9.44726H846.66318A8.93276,8.93276,0,0,0,837.732,642.62175Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#2f2e41",
          d:
            "M908.72836,423.03964s2.1598,7.19934-10.07907,13.67875-15.83855,30.95716-1.43987,38.1565,18.71828,16.55847,11.51894,23.03788-20.15815,23.75782-2.87973,28.07742,19.43821,12.95881,14.39867,16.55848,11.519-6.4794,11.519-6.4794,2.87973,17.27841,17.99834,12.95881,30.20328-82.83994,13.35031-103.0635C947.44463,427.16025,908.72836,423.03964,908.72836,423.03964Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#2f2e41",
          d:
            "M904.12952,415.62924c.00329-10.5474,31.5587-2.9686,45.088,11.75792,9.3687,10.1979,23.59878,25.54036,11.67441,36.35523s20.43938,24.99208,29.11908,29.29045,25.81718,17.443,10.34329,26.26121-15.22443,17.71981-9.401,19.82742-12.83934-3.13373-12.83934-3.13373,1.8855,17.415-13.83788,17.3316-60.83248-86.49446-50.05628-110.513C924.23976,420.47343,904.12952,415.62924,904.12952,415.62924Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#2f2e41",
          d:
            "M910.96451,413.31782c-42.19784-2.541-46.43289,29.22289-46.43289,29.22289.29172,18.38181,29.48282,9.42517,37.59043,12.16542,9.78139-7.0364,28.63565-20.35008,42.11967-29.85137A63.77377,63.77377,0,0,0,910.96451,413.31782Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "none",
          stroke: "#3f3d56",
          "stroke-miterlimit": "10",
          "stroke-width": "2",
          d: "M898.94244,616.00842S861.09039,438.609,624.16943,309.9201",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("polygon", {
        attrs: {
          fill: "#3f3d56",
          points:
            "583.431 254.891 583.905 256.836 574.322 259.16 577.341 268.549 575.435 269.16 571.76 257.724 583.431 254.891"
        }
      }),
      _c("path", {
        attrs: {
          fill: _vm.color,
          d:
            "M118.97332,797.27132c0,22.46856-13.35644,30.31375-29.83388,30.31375q-.57256,0-1.14331-.01273c-.76345-.01636-1.51962-.05271-2.26669-.10361-14.87057-1.05244-26.42387-9.30116-26.42387-30.19741,0-21.62515,27.63446-48.91242,29.71026-50.93189l.00364-.00363c.08-.07816.12-.11634.12-.11634S118.97332,774.80276,118.97332,797.27132Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#3f3d56",
          d:
            "M88.05246,824.14779,98.96409,808.901,88.02519,825.8219l-.02906,1.75044c-.76345-.01636-1.51962-.05271-2.26669-.10361l1.17607-22.47946-.0091-.17451.02-.03271.11088-2.12307L76.06109,785.69622,87.0618,801.06667l.02548.45079.88883-16.98456-9.38839-17.52807,9.50291,14.547.9252-35.21244.00364-.12v.11634L88.865,774.103l9.34661-11.008L88.8268,776.495l-.2472,15.20685,8.72677-14.59429-8.76312,16.83189-.13814,8.4541,12.66934-20.31277-12.7166,23.2629Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#2f2e41",
          d:
            "M261.27324,791.45031l6.3818-4.74121s4.22435,23.59659-.48949,27.132-22.39076,9.42769-22.39076,16.49846-40.06768,16.49845-41.24614,5.8923,3.53538-16.49845,3.53538-16.49845,18.85538-18.85538,21.2123-22.39076,8.24923-10.60615,8.24923-10.60615Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#2f2e41",
          d:
            "M166.99636,498.01351s.17679,1.03705.48306,2.93435c.283,1.6852.6718,4.06571,1.14322,7.00007,4.94962,30.204,19.38577,119.11886,18.40755,122.05322-1.17846,3.53538-9.42768,16.49845,0,20.03384.90744.34172,1.88551,1.44948,2.93436,3.19358a68.49432,68.49432,0,0,1,4.98487,11.76109c5.62128,16.30986,11.63155,42.4128,15.10795,60.49035,1.52026,7.94289,2.55717,14.33013,2.86358,17.67692a9.61182,9.61182,0,0,1,.03539,2.3334c-1.17846,4.71385,7.07076,21.2123,10.60615,25.92614s9.42769,9.42769,8.24922,14.14154,29.46153,17.67691,32.99691,9.42769,3.53539-12.96308,4.71385-15.32,2.35692-18.85537-3.53538-21.2123-2.35693-43.60306-8.24923-51.85228c-1.96794-2.74584-4.97307-12.04391-8.11962-23.2275-1.23729-4.38384-2.48653-9.05057-3.71218-13.729-4.97307-19.17358-9.3805-38.465-9.3805-38.465L237.704,493.29966l-64.81536-8.24922-1.66153,3.665-3.31154,7.27109Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#2f2e41",
          d:
            "M163.461,724.278s-9.42769-9.42769-14.14153,0-9.42769,45.96-17.67692,54.20921-18.85537,49.49537-7.07076,51.85229,24.74768-3.53539,25.92614-10.60615S176.424,776.13032,176.424,772.59494s-16.49845-16.49846-16.49845-16.49846Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#2f2e41",
          d:
            "M308.41168,433.19815s-15.32,137.87994-18.85537,150.843-22.39076,62.45843-28.28307,70.70766-43.60306,75.42151-48.3169,78.95689-14.14154,12.96307-14.14154,12.96307-17.67691,17.67692-20.03383,21.2123-22.39076-7.07076-23.56922-14.14153,1.17846-18.85538,2.35692-23.56922-2.35692-11.78461,1.17846-15.32,21.2123-21.2123,21.2123-29.46152,30.64-56.56613,42.4246-67.17228,9.42768-28.28307,9.42768-28.28307V518.04735l-64.81535-20.03384s3.53538-56.56613,11.78461-56.56613S295.44861,422.592,308.41168,433.19815Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "162.456",
          cy: "146.346",
          r: "38.889",
          fill: "#9f616a"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#9f616a",
          d:
            "M225.91941,228.14592s4.71384,17.67692,10.60615,22.39077-15.32,24.74768-15.32,24.74768l-40.06767,2.35692-7.07077-22.39076s12.96307-25.92614,9.42769-42.4246S225.91941,228.14592,225.91941,228.14592Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: _vm.color,
          d:
            "M242.41786,248.17976s-10.60615,1.17846-12.96307,4.71385-18.85537,14.14153-31.81845,11.78461-21.2123-5.89231-23.56922-4.71385-7.07076,25.92615-7.07076,25.92615l12.96307,37.71075-2.35692,103.70457-3.53539,28.28307s-7.07076-9.42769,21.2123-3.53538,64.81536-4.71385,76.6-5.89231,31.81845-4.71384,32.99691-11.78461-3.53538-32.99691-3.53538-32.99691l-18.85538-78.95689-11.78461-42.4246Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#d0cde1",
          d:
            "M231.27092,242.86089s9.96848-5.28728,13.50387-.57343,48.3169,11.78461,48.3169,15.32,9.42769,98.99073,7.07077,107.24,4.71384,17.67691,4.71384,17.67691l8.24923,35.35384s22.39076,67.17228,12.96307,69.5292-17.67692,0-21.2123,0-2.35692-44.78152-11.78461-56.56613-22.39076-41.24614-24.74768-47.13844-31.81845-87.20612-30.64-96.63381S231.27092,242.86089,231.27092,242.86089Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#d0cde1",
          d:
            "M182.31635,248.17976s-9.42769,0-10.60615,2.35693S158.74713,268.2136,154.03328,271.749,132.821,290.60436,132.821,294.13974s34.17537,29.46153,34.17537,29.46153,14.14153,69.5292,7.07076,93.09842-17.67691,80.13536-12.96307,83.67074,15.32,4.71384,15.32,0,7.07077-55.38767,12.96308-65.99382,23.56922-77.77843,16.49845-96.63381S182.31635,248.17976,182.31635,248.17976Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#9f616a",
          d:
            "M337.87321,364.84741s1.17846,36.53229-11.78461,55.38767-21.2123,40.06767-21.2123,40.06767-21.2123-5.8923-24.74768-12.96307-25.92615-1.17846-25.92615-3.53538,27.10461-14.14153,29.46153-16.49846,10.60615-2.35692,15.32-7.07076,5.89231-15.32,5.89231-15.32l4.71384-30.64Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#2f2e41",
          d:
            "M239.74117,173.52766c2.9918-.27176,6.07737-.57079,8.72149-1.99675,5.92458-3.19509,7.49563-12.29387,2.98552-17.29068a22.344,22.344,0,0,0-4.14313-3.33142l-8.95783-6.09718c-3.96186-2.69664-8.01737-5.43748-12.6382-6.70872-4.17944-1.14979-8.59364-1.03164-12.92-.76272-8.39862.522-16.84923,1.60028-24.74865,4.5s-15.2682,7.75089-20.05246,14.67333c-7.58453,10.9742-7.68878,25.77486-3.51145,38.444s12.11238,23.7014,19.9311,34.51l4.05692-19.7948c.36135-1.76309.82864-3.69543,2.295-4.7389,3.64454-2.59347,8.10267,2.73144,12.57565,2.69692,2.87408-.02218,5.28706-2.38612,6.37625-5.04591s1.1609-5.60529,1.4129-8.46838c.53374-6.06421,2.65335-14.50206,8.39619-17.84651C224.87188,173.15348,233.726,174.074,239.74117,173.52766Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M237.5596,498.96463s-3.98019,115.12729-6.33712,118.66268,0-119.02457,0-119.02457Z",
          opacity: ".1",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#d0cde1",
          d:
            "M283.664,257.60745h9.42769s40.06768,54.20921,35.35383,57.74459,0,12.96308,0,12.96308l9.42769,17.67691s11.78461,10.60615,8.24923,12.96307-8.24923,11.78461-8.24923,11.78461-23.56922-3.53538-30.64,10.60615L291.9488,362.05023Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("rect", {
        attrs: {
          width: "35",
          height: "65.224",
          x: "265.768",
          y: "279.254",
          fill: "#2f2e41",
          transform: "translate(-148.0884 80.56853) rotate(-22.43787)"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "280.75",
          cy: "304.458",
          fill: _vm.color,
          rx: "7",
          ry: "6.431",
          transform: "translate(-145.45144 79.04643) rotate(-22.43785)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#9f616a",
          d:
            "M260.09478,351.88434s-84.8492,70.70766-98.99073,48.3169,15.32-41.24614,15.32-41.24614,25.92615,1.17846,55.38767-17.67691l29.46153-12.96307s26.89605-38.399,36.06141-23.777S281.68956,344.6085,260.09478,351.88434Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#d0cde1",
          d:
            "M145.78406,289.4259,132.821,294.13974v35.35384l-4.71385,7.07076s1.17846,14.14154,4.71385,17.67692,10.60614,2.35692,8.24922,10.60615a44.667,44.667,0,0,0-1.17846,16.49845s21.2123,27.10461,22.39076,23.56922,17.67692-47.13844,20.03384-47.13844-23.56922-68.35074-23.56922-68.35074Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "#2f2e41",
          d:
            "M300.75169,467.96275s12.185-11.78461,6.09251-12.37384-27.30481-12.37384-30.8402-6.48154S300.75169,467.96275,300.75169,467.96275Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "482",
          cy: "183",
          fill: "#57b894",
          rx: "17",
          ry: "9"
        }
      }),
      _c("path", {
        attrs: {
          fill: "none",
          stroke: "#3f3d56",
          "stroke-miterlimit": "10",
          "stroke-width": "2",
          d:
            "M597.5,264.16017q-57.5-21.71653-115,0v-47a199.03781,199.03781,0,0,1,115,0Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "none",
          stroke: "#3f3d56",
          "stroke-miterlimit": "10",
          "stroke-width": "2",
          d:
            "M581.5,254.44031q-41.5-15.67368-83,0V220.51857a143.65344,143.65344,0,0,1,83,0Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "488",
          cy: "179",
          fill: "none",
          stroke: "#3f3d56",
          "stroke-miterlimit": "10",
          "stroke-width": "2",
          rx: "17",
          ry: "9"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "543.2",
          cy: "290.706",
          fill: "#57b894",
          rx: "17",
          ry: "9",
          transform: "translate(-97.66163 63.33821) rotate(-11.53546)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "none",
          stroke: "#3f3d56",
          "stroke-miterlimit": "10",
          "stroke-width": "2",
          d:
            "M612.8865,307.10126q-60.68128-9.77937-112.67713,22.99705l-9.39879-46.05065a199.03782,199.03782,0,0,1,112.67713-22.99705Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "none",
          stroke: "#3f3d56",
          "stroke-miterlimit": "10",
          "stroke-width": "2",
          d:
            "M595.266,300.77732q-43.79606-7.05816-81.32349,16.59787L507.159,284.13863a143.6534,143.6534,0,0,1,81.32349-16.59787Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "548.279",
          cy: "285.587",
          fill: "none",
          stroke: "#3f3d56",
          "stroke-miterlimit": "10",
          "stroke-width": "2",
          rx: "17",
          ry: "9",
          transform: "translate(-96.53536 64.25047) rotate(-11.53546)"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "539.675",
          cy: "346.645",
          fill: "#57b894",
          rx: "9",
          ry: "17",
          transform: "translate(105.90247 809.79432) rotate(-86.0194)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "none",
          stroke: "#3f3d56",
          "stroke-miterlimit": "10",
          "stroke-width": "2",
          d:
            "M602.43539,381.08482q-55.85373-25.65573-114.72258-7.98316l3.26268-46.88662a199.03794,199.03794,0,0,1,114.72258,7.98316Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "none",
          stroke: "#3f3d56",
          "stroke-miterlimit": "10",
          "stroke-width": "2",
          d:
            "M587.14873,370.27771Q546.83691,351.761,504.349,364.51595l2.35481-33.83991a143.6534,143.6534,0,0,1,82.79977,5.76176Z",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "545.938",
          cy: "343.071",
          fill: "none",
          stroke: "#3f3d56",
          "stroke-miterlimit": "10",
          "stroke-width": "2",
          rx: "9",
          ry: "17",
          transform: "translate(115.2961 812.71667) rotate(-86.0194)"
        }
      }),
      _c("path", {
        attrs: {
          fill: "none",
          stroke: "#3f3d56",
          "stroke-miterlimit": "10",
          "stroke-width": "2",
          d: "M465.42181,265.25137s-73.49612-10.05473-125.98187,20.70386",
          transform: "translate(-50.5 -51.15983)"
        }
      }),
      _c("polygon", {
        attrs: {
          fill: "#3f3d56",
          points:
            "290.708 224.459 292.617 225.063 289.633 234.462 299.227 236.751 298.76 238.698 287.077 235.907 290.708 224.459"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }