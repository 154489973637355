var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "mb-10 mx-2",
      attrs: {
        outlined: ""
      }
    },
    [
      _c(
        "v-card-title",
        {
          staticClass: "title pt-4",
          style: _vm.partnerColor.gradientStyle
        },
        [
          _c("v-text-field", {
            attrs: {
              dense: "",
              "append-icon": "mdi-magnify",
              label: _vm.$t(
                "components.analytics.AnalyticsDataTableDialog.search"
              ),
              "single-line": "",
              "hide-details": "",
              solo: ""
            },
            model: {
              value: _vm.search,
              callback: function callback($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          }),
          _c("v-spacer"),
          _c("thg-charging-station-meter-reading-form-dialog", {
            ref: "meterReadingFormDialog",
            attrs: {
              overflowBtn: true
            }
          })
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-data-table",
        {
          attrs: {
            headers: _vm.meterReadingHeaders,
            items: _vm.meterReadings,
            search: _vm.search,
            "items-per-page": 10,
            "sort-by": "timestamp.created",
            "sort-desc": true
          },
          scopedSlots: _vm._u(
            [
              {
                key: "item.status",
                fn: function fn(_ref) {
                  var item = _ref.item
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: {
                          bottom: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function fn(_ref2) {
                                var on = _ref2.on,
                                  attrs = _ref2.attrs
                                return [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        dark: "",
                                        color: _vm.thgStatus(item.status).color
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b({}, "v-icon", attrs, false),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.thgStatus(item.status).icon
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t(_vm.thgStatus(item.status).text))
                          )
                        ])
                      ]
                    )
                  ]
                }
              },
              {
                key: "item.timestamp.created",
                fn: function fn(_ref3) {
                  var item = _ref3.item
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          new Date(item.timestamp.created).toLocaleString(
                            "de-DE",
                            _vm.dateOptions
                          )
                        )
                      )
                    ])
                  ]
                }
              },
              {
                key: "item.meterReading.startDate",
                fn: function fn(_ref4) {
                  var item = _ref4.item
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          new Date(item.meterReading.startDate).toLocaleString(
                            "de-DE",
                            _vm.dateOptions
                          )
                        )
                      )
                    ])
                  ]
                }
              },
              {
                key: "item.meterReading.endDate",
                fn: function fn(_ref5) {
                  var item = _ref5.item
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          new Date(item.meterReading.endDate).toLocaleString(
                            "de-DE",
                            _vm.dateOptions
                          )
                        )
                      )
                    ])
                  ]
                }
              },
              {
                key: "footer.prepend",
                fn: function fn() {
                  return [_c("thg-vehicle-table-legend-dialog")]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        [
          _c(
            "template",
            {
              slot: "no-data"
            },
            [
              _c("latest-entries-card-empty", {
                staticClass: "my-6"
              }),
              _c(
                "v-container",
                {
                  staticClass: "justify-center align-center "
                },
                [
                  _c("v-card-text", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.thg.ThgChargingStationMeterReadingTable.emptyDataText"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        dense: "",
                        small: "",
                        depressed: "",
                        color: _vm.partnerColor.primary,
                        dark: ""
                      },
                      on: {
                        click: _vm.openDialog
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.thg.ThgChargingStationMeterReadingTable.emptyDataBtnText"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }