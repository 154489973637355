var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report",
    {
      attrs: {
        renderBackAndForthFooter: false,
        isProgressDone: true,
        showFooter: false
      }
    },
    [
      _c(
        "v-container",
        {
          staticClass: "padded"
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12"
                  }
                },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "text-h4 px-0"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("report.thgInsuranceCheck.title")) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "v-card-subtitle",
                        {
                          staticClass: "pl-0"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("report.thgInsuranceCheck.description")
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "px-1",
                  attrs: {
                    cols: "12"
                  }
                },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        outlined: "",
                        flat: ""
                      }
                    },
                    [
                      _c("div", {
                        staticClass: "tarif-check-iframe-wrapper ml-n2",
                        staticStyle: {
                          width: "100%",
                          height: "100%",
                          overflow: "hidden"
                        },
                        attrs: {
                          id: "tcpp-iframe-kfz",
                          "data-style": "custom_green",
                          "data-tracking": _vm.partner._id
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }