var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report",
    {
      attrs: {
        renderBackAndForthFooter: false,
        isProgressDone: true,
        showFooter: false
      }
    },
    [
      _c(
        "v-container",
        {
          staticClass: "padded"
        },
        [
          _c(
            "v-row",
            {
              staticClass: "justify-center align-center"
            },
            [
              _c(
                "v-card",
                {
                  attrs: {
                    flat: "",
                    "max-width": "500"
                  }
                },
                [
                  _c("v-card-text", [
                    _c(
                      "p",
                      {
                        staticClass: "display-1 text--primary"
                      },
                      [_vm._v(_vm._s(_vm.title))]
                    ),
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(_vm.description)
                      }
                    })
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "justify-center align-center"
            },
            [
              !_vm.isAuthenticated
                ? _c(
                    "v-card",
                    {
                      staticClass: "pt-4 pl-4 pr-4",
                      attrs: {
                        flat: "",
                        "max-width": "500"
                      }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          on: {
                            submit: function submit($event) {
                              $event.preventDefault()
                              return _vm.submit.apply(null, arguments)
                            }
                          },
                          model: {
                            value: _vm.valid,
                            callback: function callback($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              length: "6",
                              outlined: "",
                              label: _vm.textFieldLabel,
                              rules: [
                                function(v) {
                                  return !!v || "Eingabe ist erforderlich"
                                }
                              ],
                              required: "",
                              "data-test-form-password": ""
                            },
                            model: {
                              value: _vm.verificationCode,
                              callback: function callback($$v) {
                                _vm.verificationCode = $$v
                              },
                              expression: "verificationCode"
                            }
                          }),
                          _c(
                            "v-row",
                            {
                              staticClass: "justify-center align-center pt-8"
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "submitButton",
                                  attrs: {
                                    color: "success",
                                    type: "submit",
                                    rounded: "",
                                    "x-large": "",
                                    disabled: !_vm.verificationCode,
                                    loading: _vm.isLoading,
                                    "data-test-submit-button": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        left: ""
                                      }
                                    },
                                    [_vm._v(" mdi-send ")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("report.thgSuccess.btnText")
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-card",
                    {
                      staticClass: "pt-4 pl-4 pr-4",
                      attrs: {
                        flat: "",
                        "max-width": "700"
                      }
                    },
                    [
                      _c("notifications"),
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          model: {
                            value: _vm.valid,
                            callback: function callback($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "justify-center align-center pt-8 px-2"
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "success",
                                    large: "",
                                    block: "",
                                    outlined: "",
                                    "data-test-submit-register": ""
                                  },
                                  on: {
                                    click: _vm.newCar
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("report.thgSuccess.btnTextAlt")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "justify-center align-center pt-4"
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    large: "",
                                    text: "",
                                    "data-test-submit-register": ""
                                  },
                                  on: {
                                    click: _vm.goToVehicle
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "report.thgSuccess.vehicleOverview"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }