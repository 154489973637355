var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        "max-width": "850",
        flat: ""
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "v-list",
            {
              attrs: {
                "three-line": ""
              }
            },
            [
              _vm.activePromotion && _vm.activePromotion.isApplicable
                ? _c(
                    "div",
                    [
                      _c("thg-promotion-list-item", {
                        attrs: {
                          value: _vm.activePromotion
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.years, function(year, i) {
                return _c(
                  "v-list-item",
                  {
                    key: i
                  },
                  [
                    _c(
                      "v-list-item-avatar",
                      {
                        attrs: {
                          tile: "",
                          size: "60"
                        }
                      },
                      [
                        _c("v-img", {
                          staticStyle: {
                            "border-radius": "4px"
                          },
                          attrs: {
                            src: _vm.src
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("report.thgCheckoutOrderCard.title", {
                                year: year
                              })
                            )
                          )
                        ]),
                        _vm.vehicleClass
                          ? _c(
                              "v-list-item-subtitle",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      small: ""
                                    }
                                  },
                                  [_vm._v("mdi-check")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(_vm.vehicleClass.title) +
                                        " " +
                                        _vm.$t(_vm.vehicleClass.classSpeaking)
                                    ) +
                                    " "
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-list-item-subtitle",
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  small: ""
                                }
                              },
                              [_vm._v("mdi-check")]
                            ),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    _vm.price(year).paymentDateLong.token,
                                    _vm.price(year).paymentDateLong.values
                                  )
                                ) +
                                " "
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-subtitle",
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  small: ""
                                }
                              },
                              [_vm._v("mdi-check")]
                            ),
                            _vm._v(
                              _vm._s(
                                _vm.$t("report.thgCheckoutOrderCard.payment")
                              ) + " "
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-action",
                      [
                        !_vm.hideEdit
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "text-right",
                                attrs: {
                                  text: "",
                                  "x-small": "",
                                  color: "info"
                                },
                                on: {
                                  click: _vm.goToYearSelection
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")]
                            )
                          : _vm._e(),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t(_vm.price(year).prefix))),
                          _c("br")
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.price(year).totalAmountAsString))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _vm.showImpact
                ? _c("v-divider", {
                    attrs: {
                      inset: ""
                    }
                  })
                : _vm._e(),
              _vm.showImpact
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-avatar",
                        {
                          attrs: {
                            tile: "",
                            size: "60"
                          }
                        },
                        [
                          _c("v-img", {
                            staticStyle: {
                              "border-radius": "4px"
                            },
                            attrs: {
                              src: _vm.impactType.src
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "report.thgCheckoutOrderCard.sustainable",
                                    {
                                      value: _vm.$t(_vm.impactType.title)
                                    }
                                  )
                                ) +
                                " "
                            )
                          ]),
                          _c(
                            "v-list-item-subtitle",
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    small: ""
                                  }
                                },
                                [_vm._v("mdi-check")]
                              ),
                              _vm._v(
                                _vm._s(_vm.$t(_vm.impactType.subtitleAlt)) + " "
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-subtitle",
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    small: ""
                                  }
                                },
                                [_vm._v("mdi-check")]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "report.thgCheckoutOrderCard.paymentReductionHint"
                                    )
                                  ) +
                                  " "
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          !_vm.hideEdit
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "text-right",
                                  attrs: {
                                    text: "",
                                    "x-small": "",
                                    color: "info"
                                  },
                                  on: {
                                    click: _vm.goToImpactSelector
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")]
                              )
                            : _vm._e(),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("report.thgCheckoutOrderCard.reduction")
                              )
                            )
                          ]),
                          _c("span", [_vm._v(_vm._s(_vm.impact))])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showImpact
                ? _c("v-divider", {
                    attrs: {
                      inset: ""
                    }
                  })
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }