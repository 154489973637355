var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      attrs: {
        transition: "dialog-top-transition",
        "max-width": "450"
      },
      model: {
        value: _vm.dialog,
        callback: function callback($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "mx-auto",
          attrs: {
            flat: ""
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "mx-auto",
              attrs: {
                height: "300",
                "max-width": "450"
              }
            },
            [
              _c(
                "v-img",
                {
                  staticClass: "white--text",
                  attrs: {
                    height: "300",
                    src: _vm.impactTypeItem.src,
                    gradient:
                      "to top right, rgba(30, 30, 30, 0.58), rgba(30, 30, 30, 0.58)"
                  }
                },
                [
                  _c(
                    "v-card-title",
                    [
                      _vm._v(
                        _vm._s(_vm.$t(_vm.impactTypeItem.dialogTitle)) + " "
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            color: "white"
                          },
                          on: {
                            click: _vm.closeDialog
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-card-subtitle", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t(_vm.impactTypeItem.dialogSubtitle)
                      )
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }