import { render, staticRenderFns } from "./ThgProofs.vue?vue&type=template&id=47496734&scoped=true&"
import script from "./ThgProofs.vue?vue&type=script&lang=ts&"
export * from "./ThgProofs.vue?vue&type=script&lang=ts&"
import style0 from "./ThgProofs.vue?vue&type=style&index=0&id=47496734&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47496734",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VCardText,VChip})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47496734')) {
      api.createRecord('47496734', component.options)
    } else {
      api.reload('47496734', component.options)
    }
    module.hot.accept("./ThgProofs.vue?vue&type=template&id=47496734&scoped=true&", function () {
      api.rerender('47496734', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/thg/ThgProofs.vue"
export default component.exports