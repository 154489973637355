var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "mb-10 mx-2",
      attrs: {
        outlined: ""
      }
    },
    [
      _c(
        "v-card-title",
        {
          staticClass: "title pt-4",
          style: _vm.partnerColor.gradientStyle
        },
        [
          _c("v-text-field", {
            attrs: {
              dense: "",
              "append-icon": "mdi-magnify",
              label: _vm.$t(
                "components.analytics.AnalyticsDataTableDialog.search"
              ),
              "single-line": "",
              "hide-details": "",
              solo: ""
            },
            model: {
              value: _vm.search,
              callback: function callback($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          }),
          _c("v-spacer"),
          _c("thg-quote-export-dialog", {
            attrs: {
              thgs: _vm.myThgs,
              disableStatiSelector: true,
              titleStyle: _vm.partnerColor.gradientStyle,
              titleTextStyle: "color:white",
              btnColor: _vm.partnerColor.primaryDarken_2
            }
          }),
          _c(
            "v-btn",
            {
              staticClass: "d-none d-sm-block ml-2",
              attrs: {
                dense: "",
                small: "",
                depressed: "",
                color: _vm.partnerColor.primaryDarken_2,
                dark: ""
              },
              on: {
                click: _vm.newCar
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("components.thg.ThgVehicleTable.btnText")) +
                  " "
              )
            ]
          )
        ],
        1
      ),
      _c("v-divider"),
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.myThgs,
          search: _vm.search,
          "items-per-page": 10
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.status",
              fn: function fn(_ref) {
                var item = _ref.item
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        bottom: ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function fn(_ref2) {
                              var on = _ref2.on,
                                attrs = _ref2.attrs
                              return [
                                _c(
                                  "v-chip",
                                  {
                                    attrs: {
                                      dark: "",
                                      color: _vm.thgStatus(item.status).color
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b({}, "v-icon", attrs, false),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.thgStatus(item.status).icon
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.$t(_vm.thgStatus(item.status).text))
                        )
                      ])
                    ]
                  )
                ]
              }
            },
            {
              key: "item.registration.vehicleClass",
              fn: function fn(_ref3) {
                var _item$registration,
                  _vm$thgVehicleClass2,
                  _item$registration3

                var item = _ref3.item
                return [
                  _vm.thgVehicleClass(
                    (_item$registration = item.registration) === null ||
                      _item$registration === void 0
                      ? void 0
                      : _item$registration.vehicleClass
                  )
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: {
                            bottom: ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function fn(_ref4) {
                                  var _vm$thgVehicleClass, _item$registration2

                                  var on = _ref4.on,
                                    attrs = _ref4.attrs
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b({}, "v-icon", attrs, false),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (_vm$thgVehicleClass = _vm.thgVehicleClass(
                                                (_item$registration2 =
                                                  item.registration) === null ||
                                                  _item$registration2 === void 0
                                                  ? void 0
                                                  : _item$registration2.vehicleClass
                                              )) === null ||
                                                _vm$thgVehicleClass === void 0
                                                ? void 0
                                                : _vm$thgVehicleClass.icon
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    ((_vm$thgVehicleClass2 = _vm.thgVehicleClass(
                                      (_item$registration3 =
                                        item.registration) === null ||
                                        _item$registration3 === void 0
                                        ? void 0
                                        : _item$registration3.vehicleClass
                                    )) === null ||
                                    _vm$thgVehicleClass2 === void 0
                                      ? void 0
                                      : _vm$thgVehicleClass2.text) || ""
                                  )
                                )
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "footer.prepend",
              fn: function fn() {
                return [_c("thg-vehicle-table-legend-dialog")]
              },
              proxy: true
            },
            {
              key: "item.controls",
              fn: function fn(_ref5) {
                var item = _ref5.item
                return [
                  _vm.requestEmissionCalculationData(item.status)
                    ? _c(
                        "span",
                        [
                          _c("thg-emission-calculation-dialog", {
                            attrs: {
                              thg: item
                            }
                          })
                        ],
                        1
                      )
                    : item.decommissioningDate
                    ? _c(
                        "span",
                        [
                          _c(
                            "tooltip",
                            {
                              attrs: {
                                text: "Fahrzeug wurde abgemeledet"
                              }
                            },
                            [
                              _c(
                                "v-chip",
                                {
                                  attrs: {
                                    color: "error"
                                  }
                                },
                                [_vm._v("Fahrzeug Abgemeldet")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm.isUpdateRegistrationImageActivated(item)
                    ? _c(
                        "span",
                        [
                          _c("thg-quote-update-my-registration-image-dialog", {
                            attrs: {
                              myThg: item
                            }
                          })
                        ],
                        1
                      )
                    : item.status == _vm.thgStatusEnum.CREATED
                    ? _c(
                        "span",
                        [
                          _c("thg-quote-vehicle-update-registration-dialog", {
                            attrs: {
                              selectedThg: item,
                              btn: true
                            }
                          })
                        ],
                        1
                      )
                    : _vm.isQuickCheckoutEnabled &&
                      item.quickCheckoutEnabledFor &&
                      item.quickCheckoutEnabledFor.length > 0
                    ? _c(
                        "span",
                        [
                          _c("thg-quick-checkout", {
                            attrs: {
                              thg: item
                            },
                            on: {
                              refresh: _vm.refresh
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }