var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        "max-width": "850",
        flat: ""
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "v-list",
            {
              attrs: {
                "two-line": ""
              }
            },
            [
              _vm.isCompany
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                small: ""
                              }
                            },
                            [_vm._v("mdi-briefcase-account-outline")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "report.thgCheckoutPersonalDataCard.company"
                                    )
                                  ) +
                                  " "
                              ),
                              !_vm.hideEdit
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        "x-small": "",
                                        color: "info"
                                      },
                                      on: {
                                        click: _vm.goToContactData
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("edit")))]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          !_vm.isTaxDeductible
                            ? _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "report.thgCheckoutPersonalDataCard.isNotTaxDeductable"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : !_vm.isResubmitTaxnumber
                            ? _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.showValueOrDefault(
                                        _vm.taxNumber,
                                        "components.thg.ThgCreateOverviewCard.taxNumber"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _c(
                                "div",
                                [
                                  _c("v-list-item-subtitle", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.thg.ThgCreateOverviewCard.isResubmitTaxnumber"
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            small: ""
                          }
                        },
                        [_vm._v("mdi-email-outline")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "report.thgCheckoutPersonalDataCard.contact"
                                )
                              )
                          ),
                          !_vm.hideEdit
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    "x-small": "",
                                    color: "info"
                                  },
                                  on: {
                                    click: _vm.goToContactData
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("edit")))]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.isCompany
                        ? _c("v-list-item-subtitle", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.showValueOrDefault(
                                    _vm.company,
                                    "components.thg.ThgCreateOverviewCard.company"
                                  )
                                ) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      _c(
                        "v-list-item-subtitle",
                        {
                          staticClass: "mb-2"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.showValueOrDefault(
                                  _vm.name,
                                  "components.thg.ThgCreateOverviewCard.name"
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      !_vm.isResubmitAddress
                        ? _c(
                            "div",
                            [
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.showValueOrDefault(
                                        _vm.street,
                                        "components.thg.ThgCreateOverviewCard.street"
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.showValueOrDefault(
                                        _vm.address,
                                        "components.thg.ThgCreateOverviewCard.address"
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.thg.ThgCreateOverviewCard.hintAddress1"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.thg.ThgCreateOverviewCard.isResubmitAddress"
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.thg.ThgCreateOverviewCard.hintAddress2"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          ),
                      _c(
                        "v-list-item-subtitle",
                        {
                          staticClass: "mt-2"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.showValueOrDefault(
                                  _vm.email,
                                  "components.thg.ThgCreateOverviewCard.mail"
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c("v-list-item-subtitle", [
                        _vm._v(" " + _vm._s(_vm.phone) + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.showCustomerAccount
                ? _c(
                    "div",
                    [
                      !_vm.isResubmitBankingInformation
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: ""
                                      }
                                    },
                                    [_vm._v("mdi-credit-card-outline")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "report.thgCheckoutPersonalDataCard.account"
                                            )
                                          )
                                      ),
                                      !_vm.hideEdit
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                "x-small": "",
                                                color: "info"
                                              },
                                              on: {
                                                click: _vm.goToContactData
                                              }
                                            },
                                            [_vm._v(_vm._s(_vm.$t("edit")))]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.showValueOrDefault(
                                            _vm.banking.iban,
                                            "components.thg.ThgCreateOverviewCard.iban"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.showValueOrDefault(
                                            _vm.banking.bank,
                                            "components.thg.ThgCreateOverviewCard.bankname"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.showValueOrDefault(
                                            _vm.banking.name,
                                            "components.thg.ThgCreateOverviewCard.accountOwner"
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: ""
                                      }
                                    },
                                    [_vm._v("mdi-credit-card-outline")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "components.thg.ThgCreateOverviewCard.resubmitBank"
                                            )
                                          )
                                      ),
                                      !_vm.hideEdit
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                "x-small": "",
                                                color: "info"
                                              },
                                              on: {
                                                click: _vm.goToContactData
                                              }
                                            },
                                            [_vm._v(_vm._s(_vm.$t("edit")))]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.thg.ThgCreateOverviewCard.hintResubmitBank"
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ],
                    1
                  )
                : _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                small: ""
                              }
                            },
                            [_vm._v("mdi-credit-card-outline")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "report.thgPayoutConfiguration.customerAccount.titleAlt"
                                  )
                                ) +
                                " "
                            )
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "report.thgPayoutConfiguration.customerAccount.subtitle1",
                                    {
                                      company: _vm.partnerName
                                    }
                                  )
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }