var render = function() {
  var _vm$selection$chargin, _vm$selection$chargin2

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-row",
    [
      _c(
        "v-col",
        {
          attrs: {
            cols: "12",
            md: "3"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "ml-n4",
              attrs: {
                loading: _vm.loading,
                flat: ""
              }
            },
            [
              _vm.loading
                ? _c("v-skeleton-loader", {
                    attrs: {
                      type: "card-avatar, article, actions"
                    }
                  })
                : _c(
                    "div",
                    [
                      _c("debug", {
                        attrs: {
                          debug: _vm.selection
                        }
                      }),
                      _c(
                        "v-row",
                        {
                          attrs: {
                            dense: ""
                          }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "6",
                                sm: "4",
                                md: "12"
                              }
                            },
                            [
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    "three-line": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-subtitle", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.thg.ThgChargingStationCard.address"
                                            )
                                          )
                                        )
                                      ]),
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.selection.address.street)
                                        )
                                      ]),
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.selection.address.zip) +
                                            " " +
                                            _vm._s(_vm.selection.address.city)
                                        )
                                      ]),
                                      _vm.hasGeo(_vm.selection)
                                        ? _c("v-list-item-action-text", [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "https://www.google.com/maps/search/?api=1&query=" +
                                                    _vm.selection.address.geo
                                                      .lat +
                                                    "%2C" +
                                                    _vm.selection.address.geo
                                                      .lng,
                                                  target: "_blank"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " @" +
                                                    _vm._s(
                                                      _vm.selection.address.geo
                                                        .lat
                                                    ) +
                                                    "," +
                                                    _vm._s(
                                                      _vm.selection.address.geo
                                                        .lng
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ])
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "6",
                                sm: "4",
                                md: "12"
                              }
                            },
                            [
                              (_vm$selection$chargin =
                                _vm.selection.chargingPointOperator) !== null &&
                              _vm$selection$chargin !== void 0 &&
                              _vm$selection$chargin.name
                                ? _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        "two-line": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-subtitle", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.thg.ThgChargingStationCard.operator"
                                                )
                                              )
                                            )
                                          ]),
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.selection
                                                  .chargingPointOperator.name
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.isBnetzA
                            ? _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "6",
                                    sm: "4",
                                    md: "12"
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        "two-line": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-subtitle", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.thg.ThgChargingStationCard.bnetza"
                                                )
                                              )
                                            )
                                          ]),
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              _vm._s(
                                                ((_vm$selection$chargin2 =
                                                  _vm.selection
                                                    .chargingPointOperator) ===
                                                  null ||
                                                _vm$selection$chargin2 ===
                                                  void 0
                                                  ? void 0
                                                  : _vm$selection$chargin2.bnetzaOperatorId) ||
                                                  _vm.$t(
                                                    "components.thg.ThgChargingStationCard.bnetzaUnkown"
                                                  )
                                              )
                                            )
                                          ]),
                                          _c("v-list-item-title", [
                                            _vm._v(_vm._s(_vm.registrationDate))
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "6",
                                sm: "4",
                                md: "12"
                              }
                            },
                            [
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    "two-line": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-subtitle", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.thg.ThgChargingStationCard.commissioningDate"
                                            )
                                          )
                                        )
                                      ]),
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(_vm.commissioningDate))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "6",
                                sm: "4",
                                md: "12"
                              }
                            },
                            [
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    "two-line": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-subtitle", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.thg.ThgChargingStationCard.connectionPowerInKwh"
                                            )
                                          )
                                        )
                                      ]),
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selection.connectionPowerInKwh
                                          ) + " kW"
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "6",
                                sm: "4",
                                md: "12"
                              }
                            },
                            [
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    "two-line": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-subtitle", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.thg.ThgChargingStationCard.chargingPointCount"
                                            )
                                          )
                                        )
                                      ]),
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selection.chargingPointCount ||
                                              "1"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.isEvseEnabled
                            ? _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "6",
                                    sm: "4",
                                    md: "12"
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        "two-line": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-subtitle", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.thg.ThgChargingStationCard.evseId"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._l(
                                            _vm.selection.chargingPoints,
                                            function(point, i) {
                                              return _c(
                                                "v-list-item-title",
                                                {
                                                  key: i
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getEvseId(
                                                          point.evseId
                                                        )
                                                      )
                                                  )
                                                ]
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isRenewableChargingStationEnabled
                            ? _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "6",
                                    sm: "4",
                                    md: "12"
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: {
                                        "two-line": ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-subtitle", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.thg.ThgChargingStationCard.chargingStationRenewableEnergy"
                                                )
                                              ) + " "
                                            )
                                          ]),
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.isFromRenewableEnergy
                                                ) + " "
                                              ),
                                              _vm.selection
                                                .isFromRenewableEnergy
                                                ? _c(
                                                    "thg-charging-station-help-dialog",
                                                    {
                                                      attrs: {
                                                        helpTextTitle: _vm.$t(
                                                          "components.thg.ThgChargingStationForm.renewableEnergyDescription"
                                                        ),
                                                        helpText:
                                                          _vm.selection
                                                            .renewableEnergySourceDescription
                                                      }
                                                    }
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "6",
                                sm: "4",
                                md: "12"
                              }
                            },
                            [
                              _c("thg-charging-station-proof-chip-list", {
                                attrs: {
                                  files: _vm.proofs,
                                  refs: _vm.selection.refs
                                },
                                on: {
                                  "proof-update": _vm.onProofUpdate
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-col",
        {
          attrs: {
            cols: "12",
            md: "9"
          }
        },
        [
          _c("thg-charging-station-meter-reading-table", {
            attrs: {
              meterReadings: _vm.meterReadings,
              selection: _vm.selection
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }