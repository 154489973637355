var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "mx-2 mb-10",
      attrs: {
        "max-width": "515",
        flat: "",
        outlined: ""
      }
    },
    [
      _c(
        "v-img",
        {
          staticClass: "white--text align-end",
          attrs: {
            height: "100px",
            gradient:
              "to top right, rgba(30, 30, 30, 0.58), rgba(30, 30, 30, 0.58)",
            src:
              "https://static.mmmint.ai/wirkaufendeinethg/thg-overview-invite-friends.jpeg"
          }
        },
        [
          _c("v-card-title", [
            _vm._v(_vm._s(_vm.$t("components.thg.ThgInviteFriendsCard.title")))
          ])
        ],
        1
      ),
      _vm.myAffiliate.note
        ? _c("v-card-text", [_vm._v(" " + _vm._s(_vm.myAffiliate.note) + " ")])
        : _vm._e(),
      _c(
        "v-list",
        _vm._l(_vm.items, function(item) {
          return _c(
            "v-list-item",
            {
              key: item.title,
              attrs: {
                "three-line": _vm.isRenderingThreeLines
              }
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        color: _vm.partnerColorDarkmode
                      }
                    },
                    [_vm._v(" " + _vm._s(item.icon) + " ")]
                  )
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [_vm._v(_vm._s(item.title))]),
                  _c("v-list-item-subtitle", [_vm._v(_vm._s(item.subtitle))])
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c(
        "v-card-text",
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("components.thg.ThgInviteFriendsCard.registeredCars")
              ) +
              " "
          ),
          _c(
            "v-tooltip",
            {
              attrs: {
                "max-width": "250px",
                right: ""
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function fn(_ref) {
                    var on = _ref.on,
                      attrs = _ref.attrs
                    return [
                      _c(
                        "v-icon",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                small: ""
                              }
                            },
                            "v-icon",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_vm._v("mdi-information-outline")]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("components.thg.ThgInviteFriendsCard.info"))
                )
              ])
            ]
          ),
          _c("br"),
          _c(
            "span",
            {
              staticClass: "text-h4 font-weight-bold",
              style: "color:".concat(_vm.partnerColorDarkmode)
            },
            [_vm._v(_vm._s(_vm.myAffiliate.timesUsed))]
          )
        ],
        1
      ),
      _vm.myAffiliate.isValid
        ? _c(
            "span",
            [
              _c(
                "v-card-actions",
                {
                  staticClass: "mx-2"
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        block: "",
                        color: _vm.partnerColorDarkmode,
                        dark: ""
                      },
                      on: {
                        click: function click($event) {
                          return _vm.copyLinkToClipboard(_vm.myAffiliate.code)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.thg.ThgInviteFriendsCard.btnText"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-card-subtitle",
                {
                  staticClass: "text-caption"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("components.thg.ThgInviteFriendsCard.termsOfUse")
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        : _c(
            "span",
            [
              _c(
                "v-card-subtitle",
                {
                  staticClass: "text-caption"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.thg.ThgInviteFriendsCard.invalidCode"
                        )
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }