var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "600",
        transition: "dialog-bottom-transition"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
              attrs = _ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        text: "",
                        "x-small": "",
                        color: "info"
                      },
                      on: {
                        click: _vm.setCurrentImapact
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("report.thgCheckoutSustainableFactorDialog.edit")
                      ) +
                      " "
                  )
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function callback($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c(
            "v-card-title",
            {
              style: _vm.partnerColor.gradientStyle
            },
            [
              _c(
                "span",
                {
                  style: "color:white"
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("report.thgCheckoutSustainableFactorDialog.title")
                    )
                  )
                ]
              ),
              _c("v-spacer")
            ],
            1
          ),
          _vm.item.impactTypeQuestion
            ? _c(
                "v-subheader",
                {
                  staticClass: "mt-4"
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t(_vm.item.impactTypeQuestion)) + " "
                  )
                ]
              )
            : _vm._e(),
          _c(
            "v-card-text",
            {
              staticClass: "mt-4"
            },
            _vm._l(_vm.predefinedValues, function(impactfactor, idx) {
              return _c(
                "v-chip",
                {
                  key: idx,
                  staticClass: "mr-2 mb-2",
                  attrs: {
                    label: "",
                    outlined: "",
                    color: "primary"
                  },
                  on: {
                    click: function click($event) {
                      _vm.value = impactfactor
                    }
                  }
                },
                [_vm._v(_vm._s(impactfactor + " %"))]
              )
            }),
            1
          ),
          _c("v-subheader", [
            _vm._v(
              _vm._s(
                _vm.$t("report.thgCheckoutSustainableFactorDialog.description")
              )
            )
          ]),
          _c("v-slider", {
            staticClass: "mt-12 mx-4",
            attrs: {
              color: "primary",
              min: "1",
              step: "1",
              "track-color": "grey",
              "thumb-label": "always"
            },
            scopedSlots: _vm._u([
              {
                key: "thumb-label",
                fn: function fn(_ref2) {
                  var value = _ref2.value
                  return [_vm._v(" " + _vm._s(value + " %") + " ")]
                }
              }
            ]),
            model: {
              value: _vm.value,
              callback: function callback($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          }),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mb-2",
                  attrs: {
                    outlined: "",
                    color: "success"
                  },
                  on: {
                    click: _vm.close
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("report.thgCheckoutSustainableFactorDialog.close")
                    )
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mb-2",
                  attrs: {
                    color: "success",
                    elevation: "0",
                    dark: ""
                  },
                  on: {
                    click: _vm.save
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("report.thgCheckoutSustainableFactorDialog.save")
                    )
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }