var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "mb-10 mx-2",
      attrs: {
        outlined: ""
      }
    },
    [
      _c(
        "v-card-title",
        {
          staticClass: "title pt-4",
          style: _vm.partnerColor.gradientStyle
        },
        [
          _c("v-text-field", {
            attrs: {
              dense: "",
              "append-icon": "mdi-magnify",
              label: _vm.$t(
                "components.analytics.AnalyticsDataTableDialog.search"
              ),
              "single-line": "",
              "hide-details": "",
              solo: ""
            },
            model: {
              value: _vm.search,
              callback: function callback($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          }),
          _c("v-spacer"),
          _c("thg-charging-station-form-dialog", {
            ref: "chargingStationFormDialog",
            attrs: {
              overflowBtn: true
            }
          })
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-data-table",
        {
          attrs: {
            headers: _vm.headers,
            items: _vm.myChargingStation,
            search: _vm.search,
            "items-per-page": 10,
            "custom-filter": _vm.filterAll,
            "sort-by": "timestamp.created",
            "sort-desc": true
          },
          scopedSlots: _vm._u(
            [
              {
                key: "item.timestamp.created",
                fn: function fn(_ref) {
                  var item = _ref.item
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          new Date(item.timestamp.created).toLocaleString(
                            "de-DE",
                            _vm.dateOptions
                          )
                        )
                      )
                    ])
                  ]
                }
              },
              {
                key: "item.commissioningDate",
                fn: function fn(_ref2) {
                  var item = _ref2.item
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          new Date(item.commissioningDate).toLocaleString(
                            "de-DE",
                            _vm.dateOptions
                          )
                        )
                      )
                    ])
                  ]
                }
              },
              {
                key: "item.address",
                fn: function fn(_ref3) {
                  var item = _ref3.item
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(item.address.street) +
                          ", " +
                          _vm._s(item.address.zip) +
                          " " +
                          _vm._s(item.address.city)
                      )
                    ])
                  ]
                }
              },
              {
                key: "item.controls",
                fn: function fn(props) {
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          dense: "",
                          small: "",
                          depressed: "",
                          color: "info"
                        },
                        on: {
                          click: function click($event) {
                            return _vm.goToChargingStation(props.item)
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.thg.ThgChargingStationOverviewCard.manage"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  ]
                }
              }
            ],
            null,
            true
          ),
          model: {
            value: _vm.selection,
            callback: function callback($$v) {
              _vm.selection = $$v
            },
            expression: "selection"
          }
        },
        [
          _c(
            "template",
            {
              slot: "no-data"
            },
            [
              _c("latest-entries-card-empty", {
                staticClass: "my-6"
              }),
              _c(
                "v-container",
                {
                  staticClass: "justify-center align-center "
                },
                [
                  _c("v-card-text", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.thg.ThgChargingStationOverviewCard.emptyDataText"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        dense: "",
                        small: "",
                        depressed: "",
                        color: _vm.partnerColor.primary,
                        dark: ""
                      },
                      on: {
                        click: _vm.openDialog
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.thg.ThgChargingStationOverviewCard.emptyDataBtnText"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }