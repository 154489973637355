var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm.isCheckoutCardShowing
        ? _c("thg-create-overview-card", {
            attrs: {
              dialogCloseButtonFunction: _vm.dialogCloseButtonFunction,
              hideTitle: _vm.hideTitle,
              hideEdit: _vm.hideEdit
            }
          })
        : _vm._e(),
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function callback($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c("v-checkbox", {
            ref: "reviewed",
            staticClass: "mx-4",
            attrs: {
              disabled: _vm.userExists,
              rules: _vm.reportReviewedRules,
              label: _vm.$t("report.thgClosing.confirmation"),
              "data-test-review-checkbox": ""
            },
            model: {
              value: _vm.isConfirmed,
              callback: function callback($$v) {
                _vm.isConfirmed = $$v
              },
              expression: "isConfirmed"
            }
          }),
          _c("v-checkbox", {
            ref: "privacy",
            staticClass: "mx-4",
            attrs: {
              rules: [
                function(v) {
                  return !!v || _vm.$t("report.thgClosing.readPrivacy")
                }
              ],
              disabled: _vm.userExists,
              label: _vm.$t("report.thgClosing.confirmationPrivacy"),
              "data-test-privacy-checkbox": ""
            },
            model: {
              value: _vm.isTermsAccepted,
              callback: function callback($$v) {
                _vm.isTermsAccepted = $$v
              },
              expression: "isTermsAccepted"
            }
          }),
          !_vm.isAuthenticated
            ? _c("v-checkbox", {
                staticClass: "mx-4",
                attrs: {
                  disabled: _vm.userExists,
                  label: _vm.$t("report.thgClosing.marketing"),
                  "data-test-marketing-checkbox": ""
                },
                model: {
                  value: _vm.isMarketingContactAllowed,
                  callback: function callback($$v) {
                    _vm.isMarketingContactAllowed = $$v
                  },
                  expression: "isMarketingContactAllowed"
                }
              })
            : _vm._e(),
          _vm.hint
            ? _c("v-card-text", [_vm._v(" " + _vm._s(_vm.hint))])
            : _vm._e(),
          !_vm.hideSubmit
            ? _c(
                "v-row",
                {
                  staticClass: "justify-center align-center pt-8"
                },
                [
                  !_vm.userExists
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "submitButton",
                          attrs: {
                            color: "success",
                            rounded: "",
                            "x-large": "",
                            disabled: !_vm.isDone,
                            loading: _vm.isLoading,
                            "data-test-submit-button": ""
                          },
                          on: {
                            click: _vm.submit
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                left: ""
                              }
                            },
                            [_vm._v(" mdi-send ")]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("report.thgClosing.register")) +
                              " "
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.userExists
        ? _c("v-card-text", [_vm._v(" " + _vm._s(_vm.$t(_vm.userExistsText)))])
        : _vm._e(),
      _c(
        "v-row",
        {
          staticClass: "justify-center align-center pt-8 mb-8"
        },
        [
          _vm.userExists
            ? _c(
                "v-btn",
                {
                  attrs: {
                    rounded: "",
                    text: "",
                    loading: _vm.isLoading,
                    "data-test-submit-button": ""
                  },
                  on: {
                    click: _vm.goToLogin
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("report.thgClosing.login")) + " ")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }