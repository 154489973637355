var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report-step",
    {
      attrs: {
        hasNext: "false",
        title: _vm.header.title,
        description: _vm.header.description,
        showFooter: false
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "mb-8",
          attrs: {
            flat: "",
            "max-width": "800",
            outlined: ""
          }
        },
        [
          _c("thg-closing-card", {
            attrs: {
              loading: _vm.isLoading,
              userExists: _vm.userExists,
              hint: _vm.hint
            },
            on: {
              "update:loading": function updateLoading($event) {
                _vm.isLoading = $event
              },
              submit: _vm.submit
            }
          })
        ],
        1
      ),
      _c(
        "v-overlay",
        {
          attrs: {
            value: _vm.isLoading
          }
        },
        [
          _c("v-progress-circular", {
            attrs: {
              indeterminate: "",
              size: "64"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }