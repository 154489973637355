var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report-step",
    {
      attrs: {
        isDone: _vm.isDone,
        title: _vm.header.title,
        description: _vm.header.description,
        image: _vm.header.image,
        debug: _vm.header.debug,
        showFooter: false,
        hasInstructions: true,
        instructionPictures: _vm.instruction.pictures,
        instructionText: _vm.instruction.text,
        instructionTitle: _vm.instruction.title
      },
      on: {
        next: _vm.next
      }
    },
    [
      _vm.isLoading
        ? _c(
            "v-sheet",
            {
              staticClass: "mb-2 py-n6 mt-2",
              attrs: {
                rounded: "",
                outlined: ""
              }
            },
            [
              _c("v-skeleton-loader", {
                attrs: {
                  type: "article"
                }
              })
            ],
            1
          )
        : !_vm.isProductsAvailable
        ? _c(
            "v-sheet",
            {
              staticClass: "mb-2 py-n6 mt-2",
              attrs: {
                rounded: "",
                outlined: ""
              }
            },
            [
              _c(
                "v-list",
                {
                  attrs: {
                    "three-line": ""
                  }
                },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              _vm._s(_vm.$t("report.thgProducts.noItemsTitle"))
                            )
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("report.thgProducts.noItemsSubtitle")
                              ) + " "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "mailto:info@mintfuture.de"
                                }
                              },
                              [_vm._v("info@mintfuture.de")]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-4 mb-4",
                  attrs: {
                    color: "info",
                    small: ""
                  },
                  on: {
                    click: _vm.fetchPrices
                  }
                },
                [_vm._v(_vm._s(_vm.$t("report.thgProducts.noItemsBtn")))]
              )
            ],
            1
          )
        : _c(
            "v-radio-group",
            {
              model: {
                value: _vm.years,
                callback: function callback($$v) {
                  _vm.years = $$v
                },
                expression: "years"
              }
            },
            _vm._l(_vm.availableProducts, function(products, i) {
              return _c(
                "v-sheet",
                {
                  key: i,
                  staticClass: "mb-2 py-n6",
                  attrs: {
                    outlined: "",
                    rounded: "",
                    color: _vm.activeCardStyle(products.years)
                  }
                },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        flat: ""
                      },
                      on: {
                        click: function click($event) {
                          _vm.years = products.years
                        }
                      }
                    },
                    [
                      _c(
                        "v-list",
                        {
                          attrs: {
                            "three-line": ""
                          }
                        },
                        [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            products.title.token,
                                            products.title.values
                                          )
                                        ) + " "
                                      ),
                                      products.prices.length > 1
                                        ? _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                color: "success",
                                                small: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "report.thgProducts.popular"
                                                  )
                                                ) + " "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  products.prices.length > 1
                                    ? _c("v-list-item-subtitle", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.transformYearsToString(
                                              products.years
                                            )
                                          ) + " "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._l(products.prices, function(price, idx) {
                                    return _c(
                                      "div",
                                      {
                                        key: idx
                                      },
                                      [
                                        _c(
                                          "v-list-item-subtitle",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  small: ""
                                                }
                                              },
                                              [_vm._v("mdi-check")]
                                            ),
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  price.paymentDate.token,
                                                  price.paymentDate.values
                                                )
                                              )
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    "v-list-item-subtitle",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: ""
                                          }
                                        },
                                        [_vm._v("mdi-check")]
                                      ),
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "report.thgProducts.paymentDate"
                                          )
                                        ) + " "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                2
                              ),
                              _c(
                                "v-list-item-action",
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      value: products.years
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          products.prices.length > 1
                            ? _c(
                                "div",
                                {
                                  staticClass: "text-caption mb-4 mt-4 mx-4"
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: ""
                                      }
                                    },
                                    [_vm._v("mdi-check-decagram")]
                                  ),
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "report.thgProducts.multipleYear",
                                        {
                                          amount: _vm.numberToWord(
                                            products.prices.length
                                          )
                                        }
                                      )
                                    ) + " "
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }