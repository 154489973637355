var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report-step",
    {
      attrs: {
        md: _vm.md,
        isDone: _vm.isDone,
        title: _vm.header.title,
        description: _vm.header.description,
        showFooter: false
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "pt-4 pl-4 pr-4",
          attrs: {
            flat: "",
            "max-width": "500"
          }
        },
        [
          _c(
            "v-form",
            {
              ref: "form",
              attrs: {
                id: "thgPassword"
              },
              model: {
                value: _vm.isFormValid,
                callback: function callback($$v) {
                  _vm.isFormValid = $$v
                },
                expression: "isFormValid"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  type: _vm.isPasswordVisible1 ? "text" : "password",
                  label: _vm.$t("report.thgPassword.passwordLabel"),
                  rules: _vm.passwordRequirements,
                  required: "",
                  outlined: "",
                  "append-icon": _vm.isPasswordVisible1
                    ? "mdi-eye"
                    : "mdi-eye-off",
                  "data-test-form-password-1": ""
                },
                on: {
                  change: _vm.updatePassword,
                  keyup: _vm.validate,
                  "click:append": function clickAppend($event) {
                    _vm.isPasswordVisible1 = !_vm.isPasswordVisible1
                  }
                },
                model: {
                  value: _vm.password1,
                  callback: function callback($$v) {
                    _vm.password1 = $$v
                  },
                  expression: "password1"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  type: _vm.isPasswordVisible2 ? "text" : "password",
                  label: _vm.$t("report.thgPassword.passwordLabelRepeat"),
                  rules: _vm.passwordRepeatRule,
                  required: "",
                  outlined: "",
                  "append-icon": _vm.isPasswordVisible2
                    ? "mdi-eye"
                    : "mdi-eye-off",
                  "data-test-form-password-2": ""
                },
                on: {
                  keyup: _vm.validate,
                  "click:append": function clickAppend($event) {
                    _vm.isPasswordVisible2 = !_vm.isPasswordVisible2
                  }
                },
                model: {
                  value: _vm.password2,
                  callback: function callback($$v) {
                    _vm.password2 = $$v
                  },
                  expression: "password2"
                }
              })
            ],
            1
          ),
          _c("LoginComponentPasswordRequirementList", {
            attrs: {
              password: _vm.password1,
              setPass: _vm.setRequirementsFullfilled
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }