import { render, staticRenderFns } from "./ThgFrequentlyAskedQuestions.vue?vue&type=template&id=3f0b6b86&scoped=true&"
import script from "./ThgFrequentlyAskedQuestions.vue?vue&type=script&lang=ts&"
export * from "./ThgFrequentlyAskedQuestions.vue?vue&type=script&lang=ts&"
import style0 from "./ThgFrequentlyAskedQuestions.vue?vue&type=style&index=0&id=3f0b6b86&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f0b6b86",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VCardTitle,VContainer,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VIcon,VSkeletonLoader})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3f0b6b86')) {
      api.createRecord('3f0b6b86', component.options)
    } else {
      api.reload('3f0b6b86', component.options)
    }
    module.hot.accept("./ThgFrequentlyAskedQuestions.vue?vue&type=template&id=3f0b6b86&scoped=true&", function () {
      api.rerender('3f0b6b86', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/thg/ThgFrequentlyAskedQuestions.vue"
export default component.exports