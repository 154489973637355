import { render, staticRenderFns } from "./ThgPayoutOptionSelector.vue?vue&type=template&id=67b04567&scoped=true&"
import script from "./ThgPayoutOptionSelector.vue?vue&type=script&lang=ts&"
export * from "./ThgPayoutOptionSelector.vue?vue&type=script&lang=ts&"
import style0 from "./ThgPayoutOptionSelector.vue?vue&type=style&index=0&id=67b04567&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67b04567",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VCard,VChip,VList,VListItem,VListItemAction,VListItemContent,VListItemSubtitle,VListItemTitle,VRadio,VRadioGroup,VSheet,VSubheader})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('67b04567')) {
      api.createRecord('67b04567', component.options)
    } else {
      api.reload('67b04567', component.options)
    }
    module.hot.accept("./ThgPayoutOptionSelector.vue?vue&type=template&id=67b04567&scoped=true&", function () {
      api.rerender('67b04567', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/thg/ThgPayoutOptionSelector.vue"
export default component.exports