var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("v-slider", {
    attrs: {
      disabled: _vm.disabled,
      color: "green lighten-1",
      step: "10",
      ticks: "always",
      "track-color": "grey",
      "thumb-label": "always",
      "append-icon": _vm.icon,
      "prepend-icon": "mdi-currency-eur"
    },
    scopedSlots: _vm._u([
      {
        key: "thumb-label",
        fn: function fn(_ref) {
          var value = _ref.value
          return [
            _vm._v(
              " " +
                _vm._s(
                  _vm.satisfactionEmojis[Math.min(Math.floor(value / 10), 10)]
                ) +
                " "
            )
          ]
        }
      }
    ]),
    model: {
      value: _vm.sliderValue,
      callback: function callback($$v) {
        _vm.sliderValue = $$v
      },
      expression: "sliderValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }