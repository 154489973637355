var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "800",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
        transition: "dialog-bottom-transition"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
              attrs = _ref.attrs
            return [
              _vm.overflowBtn
                ? _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            elevation: "6",
                            color: _vm.partnerColor.primary,
                            fab: "",
                            fixed: "",
                            bottom: "",
                            right: ""
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            color: "white"
                          }
                        },
                        [_vm._v(" mdi-plus ")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "d-none d-sm-block ml-2",
                      attrs: {
                        dense: "",
                        small: "",
                        depressed: "",
                        color: _vm.partnerColor.primaryDarken_2,
                        dark: ""
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.thg.ThgChargingStationForm.dialogBtn"
                        )
                      ) +
                      " "
                  )
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function callback($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c(
            "v-card-title",
            {
              style: _vm.partnerColor.gradientStyle
            },
            [
              _c(
                "span",
                {
                  style: "color:white"
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("components.thg.ThgChargingStationForm.title")
                    )
                  )
                ]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: _vm.partnerColor.primaryDarken_3,
                    icon: ""
                  },
                  on: {
                    click: _vm.close
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("thg-charging-station-form", {
                on: {
                  save: _vm.save
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }