var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "mx-2 mb-10",
      attrs: {
        flat: ""
      }
    },
    [
      _c("v-text-field", {
        attrs: {
          dense: "",
          "append-icon": "mdi-magnify",
          label: _vm.$t("components.analytics.AnalyticsDataTableDialog.search"),
          "single-line": "",
          "hide-details": "",
          solo: "",
          "max-with": "200"
        },
        model: {
          value: _vm.search,
          callback: function callback($$v) {
            _vm.search = $$v
          },
          expression: "search"
        }
      }),
      _c("v-data-iterator", {
        attrs: {
          search: _vm.search,
          items: _vm.myChargingStation,
          "items-per-page": 5
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function fn(props) {
              return [
                _c(
                  "v-row",
                  _vm._l(props.items, function(myThg, i) {
                    return _c(
                      "v-col",
                      {
                        key: i,
                        attrs: {
                          cols: "12",
                          md: "6"
                        }
                      },
                      [
                        _c("thg-charging-station-card", {
                          attrs: {
                            selection: myThg,
                            meterReadings: _vm.meterReadingsByChargingStation(
                              myThg
                            )
                          }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }