var render, staticRenderFns
import script from "./ThgImpactSelectorWithCustomerAccount.vue?vue&type=script&lang=ts&"
export * from "./ThgImpactSelectorWithCustomerAccount.vue?vue&type=script&lang=ts&"
import style0 from "./ThgImpactSelectorWithCustomerAccount.vue?vue&type=style&index=0&id=331314e7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "331314e7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('331314e7')) {
      api.createRecord('331314e7', component.options)
    } else {
      api.reload('331314e7', component.options)
    }
    
  }
}
component.options.__file = "src/views/thg/ThgImpactSelectorWithCustomerAccount.vue"
export default component.exports