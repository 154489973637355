var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-list-item",
        [
          _c(
            "v-list-item-subtitle",
            [
              _vm._v(
                _vm._s(
                  _vm.$t("components.thg.ThgChargingStationProofChipList.proof")
                ) + " "
              ),
              _c("thg-charging-station-help-dialog", {
                attrs: {
                  helpTextTitle: _vm.$t(
                    "components.thg.ThgVehicleTable.help.title"
                  ),
                  helpText: _vm.$t(
                    "components.thg.ThgChargingStationProofChipList.addProofText"
                  )
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-list-item",
        {
          staticStyle: {
            "min-height": "auto"
          }
        },
        [
          _c(
            "v-list-item-content",
            {
              staticClass: "pt-0 pb-0"
            },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    dense: "",
                    small: "",
                    depressed: "",
                    dark: "",
                    color: _vm.partnerColor.primary
                  },
                  on: {
                    click: _vm.openManageFilesMenu
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "components.thg.ThgChargingStationProofChipList.upload"
                      )
                    )
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.files && _vm.files.length
        ? _c(
            "div",
            [
              _c(
                "v-list",
                _vm._l(_vm.files, function(file) {
                  return _c(
                    "v-list-item",
                    {
                      key: file.id
                    },
                    [
                      _c(
                        "v-chip",
                        {
                          attrs: {
                            color: "primary"
                          },
                          on: {
                            click: function click($event) {
                              return _vm.downloadFile(file)
                            }
                          }
                        },
                        [_vm._v(_vm._s(file.originalName))]
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            width: "600px"
          },
          model: {
            value: _vm.dialog,
            callback: function callback($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            {
              attrs: {
                flat: ""
              }
            },
            [
              _c(
                "v-card-title",
                {
                  style: _vm.partnerColor.gradientStyle
                },
                [
                  _c(
                    "span",
                    {
                      style: "color:white"
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.thg.ThgChargingStationProof.uploadProofTitle"
                          )
                        )
                      )
                    ]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: _vm.partnerColor.primaryDarken_3,
                        icon: ""
                      },
                      on: {
                        click: _vm.close
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("thg-charging-station-proof-document-table", {
                    attrs: {
                      files: _vm.displayFiles,
                      refs: _vm.refs
                    },
                    on: {
                      "file-removed": _vm.onFileRemove
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("thg-charging-station-proof", {
                    attrs: {
                      uploadedFiles: _vm.files
                    },
                    on: {
                      "file-change": _vm.onFileUpload
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                {
                  staticClass: "pl-6 pr-6"
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        dense: "",
                        small: "",
                        depressed: "",
                        color: _vm.partnerColor.primary,
                        dark: ""
                      },
                      on: {
                        click: _vm.updateChargingStationWithProofs
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.thg.ThgChargingStationProofChipList.save"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }