var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function fn(_ref) {
          var hover = _ref.hover
          return [
            _c("v-img", {
              class: _vm.colorImage(hover),
              attrs: {
                contain: "",
                src: _vm.src,
                "aspect-ratio": "1"
              }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }