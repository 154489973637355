var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "mx-auto",
      attrs: {
        flat: ""
      }
    },
    [
      _c(
        "v-card-text",
        {
          class: _vm.textStyle,
          attrs: {
            align: "center",
            justify: "center"
          }
        },
        [_vm._v(" " + _vm._s(_vm.headline) + " ")]
      ),
      _c(
        "v-row",
        {
          staticClass: "mx-4 mb-4 mt-n8",
          attrs: {
            align: "center",
            justify: "center"
          }
        },
        _vm._l(_vm.logos, function(logo) {
          return _c(
            "v-col",
            {
              key: logo,
              staticClass: "d-flex child-flex",
              attrs: {
                cols: "4",
                sm: "3",
                md: "2"
              }
            },
            [
              _c("image-on-hover-change-color", {
                staticClass: "mx-4",
                attrs: {
                  src: logo
                }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }