var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "mx-auto mt-8",
      attrs: {
        flat: ""
      }
    },
    [
      _c(
        "v-row",
        {
          attrs: {
            align: "center",
            justify: "center"
          }
        },
        [
          _c(
            "v-col",
            {
              attrs: {
                md: "6",
                order: _vm.order
              }
            },
            [
              _c(
                "v-card",
                {
                  attrs: {
                    flat: "",
                    "max-width": _vm.maxWidth
                  }
                },
                [
                  _c(
                    "v-card-text",
                    {
                      staticClass:
                        "text-md-h3 text-sm-h4 text-h4 mt-md-12  mt-4  font-weight-medium"
                    },
                    [
                      _c(
                        "span",
                        {
                          style: "color:".concat(_vm.partnerColorDarkmode)
                        },
                        [_vm._v(" " + _vm._s(_vm.$t(_vm.headline)) + " ")]
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-card",
                {
                  attrs: {
                    flat: "",
                    "max-width": "600"
                  }
                },
                [
                  _c("v-card-text", {
                    staticClass:
                      "text-md-h6 text-sm-h6 text-body-2 font-weight-regular",
                    domProps: {
                      innerHTML: _vm._s(_vm.$t(_vm.description))
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              attrs: {
                align: "center",
                justify: "center",
                cols: "12",
                md: "6"
              }
            },
            [_vm._t("default")],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }