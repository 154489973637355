var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        "max-width": "850",
        flat: ""
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "v-list",
            {
              attrs: {
                "two-line": ""
              }
            },
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            small: ""
                          }
                        },
                        [_vm._v("mdi-car-outline")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          _vm._s(_vm.$t("report.thgCheckoutVehicleCard.title"))
                        )
                      ]),
                      _c(
                        "v-list-item-subtitle",
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "report.thgCheckoutVehicleCard.numberplate"
                              )
                            )
                          ),
                          !_vm.hideEdit
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    "x-small": "",
                                    color: "info"
                                  },
                                  on: {
                                    click: _vm.goToContactData
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("edit")))]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("v-list-item-subtitle", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.showValueOrDefault(
                                _vm.numberplate,
                                "components.thg.ThgCreateOverviewCard.numberplate"
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "v-list-item-subtitle",
                        {
                          staticClass: "mt-4"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "components.thg.ThgCreateOverviewCard.registration"
                                )
                              ) +
                              " "
                          ),
                          !_vm.hideEdit
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    "x-small": "",
                                    color: "info"
                                  },
                                  on: {
                                    click: _vm.goToRegistration
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("edit")))]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("v-list-item-subtitle", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.thg.ThgCreateOverviewCard.registrationHint"
                              )
                            ) +
                            " "
                        )
                      ]),
                      _vm.isRegistrationFront
                        ? _c(
                            "v-list-item-subtitle",
                            [
                              _vm.isRegistrationFront
                                ? _c("image-dialog", {
                                    attrs: {
                                      src: _vm.getRegistrationImageFront,
                                      cardClass: "mx-0 mb-2",
                                      title: "Vorderseite"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _c("v-list-item-subtitle", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "components.thg.ThgCreateOverviewCard.registrationHint2"
                                  )
                                ) +
                                " "
                            )
                          ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }